/* eslint-disable @typescript-eslint/no-unused-vars */
/* global console setInterval, clearInterval */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getFirestore, getDoc, doc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBE4jvTTaRNKN0iXNd_B_AP4Xrwl4EXqYs",
  authDomain: "dedupeone.firebaseapp.com",
  databaseURL: "https://dedupeone-default-rtdb.firebaseio.com",
  projectId: "dedupeone",
  storageBucket: "dedupeone.appspot.com",
  messagingSenderId: "939637555230",
  appId: "1:939637555230:web:615611b6fa04962e56ae0f",
  measurementId: "G-JDNL35CYD1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
/**
 * Submits text to GPT-3 and returns the completion
 * @customfunction
 * @param {string} prompt Prompt to submit to GPT-3
 * @param {number} [temperature] Model temperature (0-1)
 * @param {string} [model] Model name (e.g. gpt-3.5-turbo-instruct)
 * @param {number} [maxTokens] Max Tokens (< 4000)
 * @returns {string} results from GPT
 */
function GPTINTERACT(prompt, temperature, model, maxTokens) {
  function todayDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);
    return today;
  }
  function dateCheck(subscription_ending_date) {
    var today_date = new Date(todayDate());
    var subscription_end_date = new Date(subscription_ending_date);
    return subscription_end_date.getTime() > today_date.getTime();
  }

  return new Promise(function (resolve) {
    function checkSubscription() {
      // eslint-disable-next-line no-async-promise-executor, no-unreachable
      return new Promise(async function (res) {
        // eslint-disable-next-line no-undef
        var email = localStorage.getItem("email");
        var emailSubscriptionExist;
        var subscriptionNotExpired;
        const docRef = doc(db, "payments", "gptforexcel");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        const emails = Object.keys(data);
        const dates = Object.values(data);
        for (var i = 0; i < emails.length; i++) {
          if (email == emails[i]) {
            emailSubscriptionExist = true;
            for (var j = 0; j < dates.length; i++) {
              subscriptionNotExpired = dateCheck(dates[i]);
              break;
            }
            break;
          } else {
            emailSubscriptionExist = false;
          }
        }
        res(emailSubscriptionExist && subscriptionNotExpired);
      });
    }
    var sub = checkSubscription();
    var sub_status;
    sub.then(async function (param) {
      sub_status = param;
      // eslint-disable-next-line no-undef
      if (sub_status == true || parseInt(localStorage.getItem("tries")) <= 10) {
        // eslint-disable-next-line no-undef
        var tries = parseInt(localStorage.getItem("tries"));
        // eslint-disable-next-line no-undef
        localStorage.setItem("tries", ++tries);
        /*if customer has paid we return the whole array*/
        // eslint-disable-next-line no-undef
        var key = localStorage.getItem("apikey");
        if (key) {
          var data = {
            prompt: prompt,
            temperature: temperature ? temperature : 0.6,
            model: model ? model : "gpt-3.5-turbo-instruct",
            max_tokens: maxTokens ? maxTokens : 256,
          };
          var xhr = new XMLHttpRequest();
          xhr.open("POST", "https://api.openai.com/v1/completions", true);
          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.setRequestHeader("Authorization", "Bearer " + key);

          xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
              var response = JSON.parse(xhr.responseText);
              resolve(response.choices[0].text.trim());
            }
            if (xhr.status === 401) {
              resolve("Invalid ChatGPT key");
            }
            if (xhr.status === 404) {
              resolve(
                "Model not found or its a chat model that cannot be used with completions api, or your subscription does not allow to use this model"
              );
            }
          };

          xhr.send(JSON.stringify(data));
          xhr.onerror = function (event) {
            resolve(event.error);
          };
        } else {
          resolve("No API Key Found! You have not subscribed to the ChatGPT key yet!");
        }
      } else {
        /*if the customer has not paid we slice the array and return only subset of the array*/

        // var rs = return_array.slice(0, 20);
        // rs.push("• Only Top 20 records shown.Please subscribe to enjoy unlimited records lookups.");
        // rs.push("• To subscribe, click on Extensions menu above then click on Fuzzy Lookup for Sheets.");
        // rs.push("• If no records were shown in the top 20 above please lower the threshold");
        // rs.push("• for assistance contact us on smallpdfkenya@gmail.com");
        // resolve(rs);
        resolve(
          `No subscription Found! You have not subscribed to the GPT for Excel and 
          Your 10 free API calls have all been used.Please subscribe on the Side Menu.
          For assistance contact us on smallpdfkenya@gmail.com`
        );
      }
    });
  });
}

/**
 * Submits instructions and input data to GPT-3 and returns the completion
 * @customfunction
 * @param {string} order instructions to submit to GPT-3
 * @param {string} input_data goal to submit to GPT-3
 * @param {number} [temperature] Model temperature (0-1)
 * @param {string} [model] Model name (e.g. gpt-3.5-turbo-instruct)
 * @param {number} [maxTokens] Max Tokens (< 4000)
 * @returns {string} results from GPT
 */
function GPTPROMPT(order, input_data, temperature, model, maxTokens) {
  function todayDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);
    return today;
  }
  function dateCheck(subscription_ending_date) {
    var today = todayDate();
    var today_date = new Date(today);
    var subscription_end_date = new Date(subscription_ending_date);
    return subscription_end_date.getTime() > today_date.getTime();
  }

  return new Promise(function (resolve) {
    function checkSubscription() {
      // eslint-disable-next-line no-async-promise-executor, no-unreachable
      return new Promise(async function (res) {
        // eslint-disable-next-line no-undef
        var email = localStorage.getItem("email");
        var emailSubscriptionExist;
        var subscriptionNotExpired;
        const docRef = doc(db, "payments", "gptforexcel");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        const emails = Object.keys(data);
        const dates = Object.values(data);
        for (var i = 0; i < emails.length; i++) {
          if (email == emails[i]) {
            emailSubscriptionExist = true;
            for (var j = 0; j < dates.length; i++) {
              subscriptionNotExpired = dateCheck(dates[i]);
              break;
            }
            break;
          } else {
            emailSubscriptionExist = false;
          }
        }
        res(emailSubscriptionExist && subscriptionNotExpired);
      });
    }
    var sub = checkSubscription();
    var sub_status;
    sub.then(async function (param) {
      sub_status = param;
      // eslint-disable-next-line no-undef
      if (sub_status == true || parseInt(localStorage.getItem("tries")) <= 10) {
        // eslint-disable-next-line no-undef
        var tries = parseInt(localStorage.getItem("tries"));
        // eslint-disable-next-line no-undef
        localStorage.setItem("tries", ++tries);
        /*if customer has paid we return the whole array*/
        // eslint-disable-next-line no-undef
        var key = localStorage.getItem("apikey");
        if (key) {
          var data = {
            prompt: order + " " + input_data,
            temperature: temperature ? temperature : 0.6,
            model: model ? model : "gpt-3.5-turbo-instruct",
            max_tokens: maxTokens ? maxTokens : 256,
          };
          var xhr = new XMLHttpRequest();
          xhr.open("POST", "https://api.openai.com/v1/completions", true);
          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.setRequestHeader("Authorization", "Bearer " + key);

          xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
              var response = JSON.parse(xhr.responseText);
              resolve(response.choices[0].text.trim());
            }
            if (xhr.status === 401) {
              resolve("Invalid ChatGPT key");
            }
            if (xhr.status === 404) {
              resolve(
                "Model not found or its a chat model that cannot be used with completions api, or your subscription does not allow to use this model"
              );
            }
          };

          xhr.send(JSON.stringify(data));
          xhr.onerror = function (event) {
            resolve(event.error);
          };
        } else {
          resolve("No API Key Found! You have not subscribed to the ChatGPT key yet!");
        }
      } else {
        /*if the customer has not paid we slice the array and return only subset of the array*/

        // var rs = return_array.slice(0, 20);
        // rs.push("• Only Top 20 records shown.Please subscribe to enjoy unlimited records lookups.");
        // rs.push("• To subscribe, click on Extensions menu above then click on Fuzzy Lookup for Sheets.");
        // rs.push("• If no records were shown in the top 20 above please lower the threshold");
        // rs.push("• for assistance contact us on smallpdfkenya@gmail.com");
        // resolve(rs);
        resolve(
          `No subscription Found! You have not subscribed to the GPT for Excel and 
          Your 10 free API calls have all been used.Please subscribe on the Side Menu.
          For assistance contact us on smallpdfkenya@gmail.com`
        );
      }
    });
  });
}

/**
 * Uses sample examples to autopopulate cells.Ensure to lock the input and output examples
 * @customfunction
 * @param {string[][]} examples_input input array to submit to GPT3
 * @param {string[][]} examples_output out array to submit to GPT3
 * @param {string} input input value
 * @param {number} [temperature] Model temperature (0-1)
 * @param {string} [model] Model name (e.g. gpt-3.5-turbo-instruct)
 * @param {number} [maxTokens] Max Tokens (< 4000)
 * @returns {string} returns the predicted output based on range input
 */
function GPTPREDICT(examples_input, examples_output, input, temperature, model, maxTokens) {
  function todayDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);
    return today;
  }
  function dateCheck(subscription_ending_date) {
    var today = todayDate();
    var today_date = new Date(today);
    var subscription_end_date = new Date(subscription_ending_date);
    return subscription_end_date.getTime() > today_date.getTime();
  }

  return new Promise(function (resolve) {
    function checkSubscription() {
      // eslint-disable-next-line no-async-promise-executor, no-unreachable
      return new Promise(async function (res) {
        // eslint-disable-next-line no-undef
        var email = localStorage.getItem("email");
        var emailSubscriptionExist;
        var subscriptionNotExpired;
        const docRef = doc(db, "payments", "gptforexcel");
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        const emails = Object.keys(data);
        const dates = Object.values(data);
        for (var i = 0; i < emails.length; i++) {
          if (email == emails[i]) {
            emailSubscriptionExist = true;
            for (var j = 0; j < dates.length; i++) {
              subscriptionNotExpired = dateCheck(dates[i]);
              break;
            }
            break;
          } else {
            emailSubscriptionExist = false;
          }
        }
        res(emailSubscriptionExist && subscriptionNotExpired);
      });
    }
    var sub = checkSubscription();
    var sub_status;
    sub.then(async function (param) {
      sub_status = param;
      // eslint-disable-next-line no-undef
      if (sub_status == true || parseInt(localStorage.getItem("tries")) <= 10) {
        // eslint-disable-next-line no-undef
        var tries = parseInt(localStorage.getItem("tries"));
        // eslint-disable-next-line no-undef
        localStorage.setItem("tries", ++tries);
        /*if customer has paid we return the whole array*/
        // eslint-disable-next-line no-undef
        var key = localStorage.getItem("apikey");
        if (key) {
          var prompt = `I am an input/output bot. Given example inputs, I identify the pattern and produce the associated outputs.`;

          for (let i = 0; i < examples_input.length; i++) {
            var example_input = examples_input[i];
            var example_output = examples_output[i];

            prompt += `

Input: ${example_input}
Output: ${example_output}`;
          }

          prompt += `

Input: ${input}
Output:`;

          var xhr = new XMLHttpRequest();
          xhr.open("POST", "https://api.openai.com/v1/completions", true);
          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.setRequestHeader("Authorization", "Bearer " + key);

          xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
              var response = JSON.parse(xhr.responseText);
              resolve(response.choices[0].text.trim());
            }
            if (xhr.status === 401) {
              resolve("Invalid ChatGPT key");
            }
            if (xhr.status === 404) {
              resolve(
                "Model not found or its a chat model that cannot be used with completions api, or your subscription does not allow to use this model"
              );
            }
          };

          var data = {
            prompt: prompt,
            temperature: temperature ? temperature : 0.6,
            model: model ? model : "gpt-3.5-turbo-instruct",
            max_tokens: maxTokens ? maxTokens : 256,
          };

          xhr.send(JSON.stringify(data));
          xhr.onerror = function (event) {
            resolve(event.error);
          };
        } else {
          resolve("No API Key Found! You have not subscribed to the RapidApi key yet!");
        }
      } else {
        /*if the customer has not paid we slice the array and return only subset of the array*/

        // var rs = return_array.slice(0, 20);
        // rs.push("• Only Top 20 records shown.Please subscribe to enjoy unlimited records lookups.");
        // rs.push("• To subscribe, click on Extensions menu above then click on Fuzzy Lookup for Sheets.");
        // rs.push("• If no records were shown in the top 20 above please lower the threshold");
        // rs.push("• for assistance contact us on smallpdfkenya@gmail.com");
        // resolve(rs);
        resolve(
          `No subscription Found! You have not subscribed to the GPT for Excel and 
          Your 10 free API calls have all been used.Please subscribe on the Side Menu.
          For assistance contact us on smallpdfkenya@gmail.com`
        );
      }
    });
  });
}

CustomFunctions.associate("GPTINTERACT", GPTINTERACT);
CustomFunctions.associate("GPTPROMPT", GPTPROMPT);
CustomFunctions.associate("GPTPREDICT", GPTPREDICT);